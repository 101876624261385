@use '@angular/material' as mat;
@use '../../styles/mixins/ui-helpers';

@mixin ui-notification-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    @include mat.snack-bar-theme($theme);

    .mat-mdc-snack-bar-container {
        .mdc-snackbar__surface {
            --mdc-snackbar-supporting-text-color: #{mat.get-color-from-palette(
                    $background,
                    status-bar
                )};
            --mdc-snackbar-container-color: #{mat.get-color-from-palette(
                    $foreground,
                    base
                )};
        }

        &--error {
            --mdc-snackbar-supporting-text-color: #{mat.get-color-from-palette(
                    $warn,
                    default-contrast
                )};

            @media print {
                visibility: hidden;
            }

            .mdc-snackbar__surface {
                --mdc-snackbar-container-color: #{mat.get-color-from-palette(
                        $warn,
                        default
                    )};
            }
        }

        &--no-padding {
            .mat-mdc-snack-bar-label {
                padding: 0 !important;
            }

            @media print {
                visibility: hidden;
            }
        }
    }
}
