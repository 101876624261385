@mixin gradient-background(
    $gradient-deg: 0deg,
    $gradient-from: #3c3f3e,
    $gradient-to: #606161
) {
    background: transparent
        linear-gradient(
            $gradient-deg,
            #{$gradient-from} 0%,
            #{$gradient-from} 30%,
            #{$gradient-to} 70%,
            #{$gradient-to} 100%
        )
        0 0 no-repeat padding-box;
}

@mixin blurry-background(
    $background-color,
    $fallback-background-color,
    $blur-radius: 40px
) {
    background: $fallback-background-color;
    @supports (
        (-webkit-backdrop-filter: blur(#{$blur-radius})) or
            (backdrop-filter: blur(#{$blur-radius}))
    ) {
        background: $background-color;
        backdrop-filter: blur(#{$blur-radius});
        -webkit-backdrop-filter: blur(#{$blur-radius});
    }
}

@mixin clear-blurry-background() {
    background: none;
    @supports (
        (-webkit-backdrop-filter: blur(40px)) or (backdrop-filter: blur(40px))
    ) {
        background: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
}
