@use '@angular/material' as mat;
@use 'libs/ui-components/src/styles/mixins/ui-helpers';
@use '../mixins/background';

@mixin ui-grid-list-item-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);

    .ui-grid-list-item {
        &:not(.ui-grid-list-item--disabled):not(.ui-grid-list-item--readonly) {
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: mat.get-color-from-palette($foreground, base, 0.4);

                @include ui-helpers.transition-visibility-hidden();
            }

            &:focus {
                &:before {
                    @include ui-helpers.transition-visibility-visible();
                }
            }
        }
    }
}
