@use '@angular/material' as mat;

@mixin scrollbars($theme) {
    html.has-scrollbars {
        // firefox
        scrollbar-width: thin;
        * {
            scrollbar-width: thin;
        }

        // webkit
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: mat.get-color-from-palette(
                mat.$grey-palette,
                600,
                0.1
            );
        }

        ::-webkit-scrollbar-thumb {
            &:vertical,
            &:horizontal {
                background-color: mat.get-color-from-palette(
                    mat.$grey-palette,
                    600,
                    0.8
                );
                border-radius: 5px;
            }
        }

        ::-webkit-scrollbar-button:start:decrement,
        ::-webkit-scrollbar-button:end:increment {
            display: none;
        }

        ::-webkit-scrollbar-track-piece {
            background-color: mat.get-color-from-palette(
                mat.$grey-palette,
                600,
                0.1
            );
        }
    }
}
