@use '@angular/material' as mat;

@use 'material-theme';
@use 'ui-components-theme';
@use 'scrollbars';

/**
 * Includes all necessary styles for the ui-components.
 *
 * For general information on how a (material-) theme is structured, consult
 * - https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
 * - https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html
 * - node_modules/@angular/material/_theming.scss
 *
 * In case you want to get your own color values from the $theme, use map-get first to get the appropriate palette / sub-map
 * from the theme (one of the palettes 'primary', 'accent', 'warn' or one of 'foreground' and 'background') and then
 * extract colors using mat.get-color-from-palette function.
 * For palettes, you may extract different hues from the palette (e.g. mat.get-color-from-palette($primary, lighter) or mat.get-color-from-palette($accent, darker) -
 * see @function mat-palette).
 * For 'foreground' and 'background', see node_modules/@angular/material/_theming.scss: $mat-light-theme-foreground / $mat-light-theme-background
 * for available values.
 */
@mixin mhp-theme($theme: material-theme.$theme, $skip-component-themes: false) {
    // Theme Init

    // include a subset of mat-core() mixin excluding the font-definitions
    @include mat.core();

    @include mat.typography-hierarchy(material-theme.$mat-typography);

    @include mat.core-theme($theme);

    @if $skip-component-themes == false {
        @include ui-components-theme.ui-components-theme($theme);
    }
    @include scrollbars.scrollbars($theme);

    * {
        box-sizing: border-box;

        // disable tap-highlighting
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;

        &:before,
        &:after {
            box-sizing: inherit;
        }
    }
}
