@use '@angular/material' as mat;

@mixin ui-form-field-theme($theme) {
    @include mat.form-field-theme($theme);

    $is-dark-theme: map-get($theme, is-dark);

    mat-form-field {
        &.mhp-ui-form-field {
            &--inherit {
                width: inherit;
            }

            &--full-width {
                width: 100%;
            }

            &--adapt-width-to-content {
                .mat-select-value {
                    max-width: none;
                }

                .mat-mdc-form-field-infix {
                    width: auto;
                }
            }

            &--no-underline {
                .mdc-line-ripple {
                    display: none;
                }
            }

            &--no-bottom-spacing {
                .mat-mdc-form-field-subscript-wrapper {
                    display: none;
                }
            }

            &--no-placeholder {
                .mat-form-field-infix {
                    border-top: 0;
                    padding: 0.9286em 0;
                }
            }

            &--enlarged {
                .mat-form-field-infix {
                    border-top: 0.7em solid transparent;
                    padding: 0.9286em 0;
                }

                .mat-mdc-form-field-label-wrapper {
                    top: -0.5em;
                }
            }
        }

        .mat-mdc-form-field-focus-overlay {
            display: none;
        }

        .mat-mdc-form-field-infix {
            min-height: 0;
        }

        .mdc-text-field {
            padding: 0;

            &--filled:not(.mdc-text-field--disabled) {
                background-color: transparent !important;
            }

            &--disabled {
                background-color: transparent !important;

                .mdc-line-ripple::before {
                    border-bottom-color: if($is-dark-theme, #fff, #b3b3b3);
                    border-bottom-style: dashed;
                }
            }
        }
    }
}
