@use '@angular/material' as mat;
@use 'libs/ui-components/src/styles/mixins/ui-helpers';

@mixin ui-selected-marker-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .ui-selected-marker {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        border: none;
        background-color: mat.get-color-from-palette($primary);

        @include ui-helpers.transition-visibility-hidden();

        &:before,
        &:after {
            display: none !important;
        }

        &__image-selected-icon {
            display: none;
        }

        &--selected {
            @include ui-helpers.transition-visibility-visible();
        }
    }
}
