@use '@angular/material' as mat;

@use '../material-theme';
@use '../variables';
@use '../breakpoints';

@mixin ui-buttons-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, primary);

    $button-padding: 16px;
    $button-height: 60px;
    $line-height: 1.5;

    button,
    a {
        &.mdc-button,
        &.mdc-button.mat-mdc-button-base {
            --mdc-filled-button-container-height: #{$button-height};
            --mat-mdc-button-persistent-ripple-color: currentColor;
            --mdc-text-button-container-shape: 2px;

            font-size: variables.$font-size-l;
            font-weight: variables.$font-weight-medium;
            letter-spacing: 0.7px;
            height: auto;
            min-height: $button-height;
            line-height: $line-height;
            padding: 0 16px;
            box-shadow: 0 1px 4px 0 rgba(0, 64, 58, 0.1),
                0px -1px 0px 0px rgba(0, 64, 58, 0.1) inset;

            transition: color variables.$transition-fade-duration-fast,
                background-color variables.$transition-fade-duration-fast;

            .mdc-button__label {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.mdc-button {
            &.mdc-button--outlined {
                border-width: 2px;
                line-height: $button-height - 4px;

                &:not(:disabled) {
                    border-color: rgba(
                        mat.get-color-from-palette($foreground, base),
                        0.2
                    );
                }
            }

            &.mdc-button--unelevated,
            &.mdc-button--raised {
                @if map-get($theme, is-dark) {
                    --mdc-filled-button-container-color: #{mat.get-color-from-palette(
                            $foreground,
                            base,
                            1
                        )};
                    --mdc-filled-button-label-text-color: #{variables.$aml-green};
                } @else {
                    --mdc-filled-button-container-color: #{variables.$aml-green};
                    --mdc-filled-button-label-text-color: #{mat.get-color-from-palette(
                            $background,
                            status-bar,
                            1
                        )};
                }

                .mat-ripple-element {
                    opacity: 0.1;
                    --mat-mdc-button-ripple-color: currentColor;
                    background-color: var(--mat-mdc-button-ripple-color);
                }

                .mat-mdc-button-focus-overlay {
                    background: mat.get-color-from-palette(
                        $background,
                        status-bar,
                        0.1
                    );
                }
            }
        }

        .mat-mdc-button-wrapper {
            line-height: 1;
        }

        &.mdc-button.mhp-ui-button {
            &--link-style {
                font-weight: variables.$font-weight-normal;
                text-decoration: underline;
                text-transform: initial;
                margin-left: -1 * $button-padding;
                margin-right: -1 * $button-padding;

                &:hover {
                    text-decoration: none;
                }
            }

            &--full-width {
                display: block;
                width: 100%;
                max-width: none;
            }

            &--block {
                display: block;
            }

            &--inherit-font-styles {
                font-weight: inherit;
                line-height: inherit;
                font-size: inherit;
                letter-spacing: inherit;

                .mat-mdc-button-wrapper {
                    > * {
                        vertical-align: inherit;
                    }
                }
            }

            &--compensate-padding {
                margin-left: -1 * $button-padding; // adheres to the default button-padding
                margin-right: -1 * $button-padding; // adheres to the default button-padding
            }

            &--right-align {
                margin-right: -1 * $button-padding; // adheres to the default button-padding
            }

            &--left-align {
                margin-left: -1 * $button-padding; // adheres to the default button-padding
            }

            &--no-negative-margin {
                margin-right: 0;
                margin-left: 0;
            }

            &--highlight {
                --mdc-filled-button-container-color: #{mat.get-color-from-palette(
                        $foreground,
                        base
                    )};
                --mdc-filled-button-label-text-color: #{variables.$aml-green};
            }

            &--prominent {
                --mdc-filled-button-container-color: #{variables.$aml-green};
                --mdc-filled-button-label-text-color: #{mat.get-color-from-palette(
                        $foreground,
                        base
                    )};
            }

            &--fade {
                --mdc-filled-button-container-color: #{mat.get-color-from-palette(
                        $foreground,
                        base,
                        0.15
                    )};
                --mdc-filled-button-label-text-color: #{mat.get-color-from-palette(
                        $foreground,
                        base,
                        1
                    )};
                box-shadow: 0 1px 3px 0 rgba(18, 18, 18, 0.2),
                    0px -1px 0px 0px rgba(18, 18, 18, 0.08) inset,
                    0px 1px 0.5px 0px rgba(255, 255, 255, 0.12) inset;
                backdrop-filter: blur(35px);
            }

            &--no-drop-shadow {
                box-shadow: none;
            }
        }

        mhp-ui-icon {
            line-height: 1;

            &:first-child {
                margin-right: variables.$spacing-s;
            }

            &:last-child {
                margin-left: variables.$spacing-s;
            }
        }
    }
}

@mixin ui-button-invert($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .mdc-button {
        .mat-mdc-ripple-element {
            opacity: 0.1;
            background-color: currentColor;
        }

        &.mat-mdc-button {
            background-color: #e6e6e6;
            color: variables.$aml-green;

            &:disabled {
                background-color: rgba(#eef0f0, 0.7);
                color: rgba(#00665e, 0.3);
            }
        }

        &.mdc-button--unelevated {
            background-color: variables.$aml-green;
            color: mat.get-color-from-palette($foreground, base);
        }

        &.mdc-button--outlined {
            &:not(:disabled) {
                border-color: rgba(
                    mat.get-color-from-palette($background, status-bar),
                    0.2
                );
            }
        }
    }
}
