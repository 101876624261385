@use '../variables';

@mixin ui-expansion-panel-theme-overrides($theme) {
    $background: map-get($theme, background);

    mhp-ui-expansion-panel {
        margin-bottom: 0;
        border-bottom: 2px solid rgba(#53565a, 0.6);

        .expansion-panel {
            .mat-expansion-panel {
                background-color: transparent;
            }

            .mat-expansion-panel-header {
                background-color: transparent !important;
                transition: background-color
                    variables.$transition-fade-duration-fast;
            }

            .mat-expansion-panel-body {
                padding: variables.$spacing-m;
            }

            &--highlight {
                .mat-expansion-panel-header {
                    background-color: #63666a !important;
                }
            }
        }
    }
}
