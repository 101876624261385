@use '@angular/material' as mat;
@use '../../styles/mixins/ui-helpers';

@mixin ui-mat-dialog-theme($theme) {
    @include mat.dialog-theme($theme);

    $background: map-get($theme, background);

    .mhp-ui-modal-backdrop {
        background-color: mat.get-color-from-palette(
            $background,
            status-bar,
            0.3
        );

        &--opaque {
            background-color: mat.get-color-from-palette(
                $background,
                status-bar
            );
        }
    }

    .mhp-ui-modal-panel {
        .mat-mdc-dialog-container {
            --mdc-dialog-container-shape: 0;

            max-width: 100%;
            max-height: 100vh;

            .mat-mdc-dialog-surface {
                position: static;
            }
        }

        &--fullscreen {
            .mat-dialog-container {
                box-shadow: none;
            }
        }
    }
}
