@use '../../styles/variables';

@mixin transition-visibility-hidden(
    $is-default-state: true,
    $transition-duration: variables.$transition-fade-duration-fast,
    $skip-will-change: false
) {
    visibility: hidden;
    opacity: 0;

    @if $is-default-state {
        transition: visibility #{$transition-duration},
            opacity #{$transition-duration};
        @if $skip-will-change != true {
            will-change: visibility, opacity;
        }
    }
}

@mixin transition-visibility-visible(
    $opacity: 1,
    $is-default-state: false,
    $transition-duration: variables.$transition-fade-duration-fast,
    $skip-will-change: false
) {
    visibility: visible;
    opacity: $opacity;

    @if $is-default-state {
        transition: visibility #{$transition-duration},
            opacity #{$transition-duration};
        @if $skip-will-change != true {
            will-change: visibility, opacity;
        }
    }
}

@mixin vertical-scrollable() {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@mixin hide-scrollbars() {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin divider-list($skip-last: false, $border-color) {
    border-bottom: 1px solid $border-color;

    @if ($skip-last) {
        &:last-child {
            border-bottom: none;
        }
    }
}

@mixin sr-hide() {
    // taken from angular-material .cdk-visually-hidden
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    // Avoid browsers rendering the focus ring in some cases.
    outline: 0;

    // Avoid some cases where the browser will still render the native controls (see #9049).
    -webkit-appearance: none;
    -moz-appearance: none;
}
