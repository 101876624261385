@use '@angular/material' as mat;
@use '../../styles/mixins/ui-helpers';

@mixin ui-expansion-panel-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .expansion-panel {
        &:not(:last-child) {
            &:after {
                @include ui-helpers.divider-list(
                    $border-color:
                        mat.get-color-from-palette($foreground, divider)
                );
            }
        }

        &__expansion-state-indicator {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider, 0.3);
            color: mat.get-color-from-palette($foreground, icon);

            &:after {
                border-color: mat.get-color-from-palette(
                    $foreground,
                    divider,
                    0.3
                );
            }
        }

        &__title {
            color: mat.get-color-from-palette($foreground, text);
        }

        &__subtitle {
            color: mat.get-color-from-palette($foreground, secondary-text, 0.5);
        }

        @include mat.expansion-theme($theme);

        .mat-expansion-panel-header {
            background-color: mat.get-color-from-palette($background, card);
        }

        // TODO: Workaround for https://github.com/angular/material2/issues/5269
        .mat-expansion-panel-header:not(.mat-expanded) {
            height: 40px;
        }

        .mat-expansion-panel-header {
            height: 40px;

            &.mat-expanded {
                .mat-expansion-indicator {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
