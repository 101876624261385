@use '@angular/material' as mat;

@mixin ui-icon-button-theme($theme) {
    @include mat.icon-button-theme($theme);

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    $is-dark-theme: map-get($theme, is-dark);

    .mhp-icon-button {
        &.mat-mdc-icon-button {
            padding: 0;
            font-size: 0;
            line-height: 1;

            --mdc-icon-button-state-layer-size: 40px;
            --mdc-icon-button-disabled-icon-color: #{if(
                    $is-dark-theme,
                    #ffffff4d,
                    #0000004d
                )};
        }

        .mat-ripple-element {
            opacity: 0.1;
            --mat-mdc-button-ripple-color: currentColor;
        }
    }
}
