@use '@angular/material' as mat;

@mixin ui-lazyload-image-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, primary);

    mhp-ui-lazyload-image {
        transition: background 0.3s;
        background: transparent;

        &.lazyload-image {
            &--loading {
                background: rgba(
                    mat.get-color-from-palette($background, status-bar),
                    0.2
                );
            }
        }

        .lazyload-image {
            &__loading-spinner-inner {
                &:after {
                    border-color: rgba(
                        mat.get-color-from-palette($foreground, base),
                        0.3
                    );
                    border-top-color: mat.get-color-from-palette(
                        $foreground,
                        base
                    );
                }
            }
        }
    }
}
