@use '@angular/material' as mat;

@mixin ui-select-theme($theme) {
    @include mat.select-theme($theme);

    $is-dark-theme: map-get($theme, is-dark);

    :root {
        --mat-option-hover-state-layer-color: #{if(
                $is-dark-theme,
                rgba(white, 0.08),
                rgba(black, 0.04)
            )};
        --mat-option-selected-state-layer-color: #{if(
                $is-dark-theme,
                rgba(white, 0.08),
                rgba(black, 0.04)
            )};
    }
}
