@use '@angular/material' as mat;

@mixin ui-selected-marker-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $theme-colors: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    );

    .ui-selected-marker {
        &:before {
            background-color: mat.get-color-from-palette($primary);
        }

        &__image-selected-icon {
            mhp-ui-icon {
                background-color: mat.get-color-from-palette($foreground, base);
            }
        }
    }

    @each $name, $color in $theme-colors {
        .ui-selected-marker {
            &.mat-#{$name} {
                &:before {
                    background-color: mat.get-color-from-palette($color);
                }

                .ui-selected-marker__image-selected-icon {
                    mhp-ui-icon {
                        color: mat.get-color-from-palette($color);
                    }
                }
            }
        }
    }
}
