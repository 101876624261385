@use 'libs/aml/ui-components-theme/src/styles/variables';

@use 'libs/aml/ui-components-theme/src/styles/breakpoints';

.legal-text {
    h1 {
        font-size: variables.$font-size-title-xl;
        font-weight: variables.$font-weight-medium;
    }

    h2 {
        font-size: variables.$font-size-title-l;
        font-weight: variables.$font-weight-medium;
    }

    p {
        white-space: normal !important;
    }

    table {
        th {
            text-align: left;
        }

        td {
            vertical-align: top;
        }
    }

    a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    &--cookie-policy {
        @media #{breakpoints.$handset-portrait} {
            mhp-ui-mat-dialog-actions-right {
                flex-flow: column;
            }
        }
    }
}
