@use '../mixins/background';
@use '../../styles/variables' as vars;

@mixin ui-tooltip-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);

    .mdc-tooltip {
        @include background.blurry-background(
            rgba(#000, 0.33),
            rgba(#000, 0.95)
        );
        border-radius: var(--mdc-plain-tooltip-container-shape);

        // increase specifity to not be forced to use !important
        .mdc-tooltip__surface.mdc-tooltip__surface {
            font-size: vars.$font-size-xs;
            background-color: transparent;
        }
    }
}
