@use '@angular/material' as mat;

@mixin ui-radio-theme($theme) {
    @include mat.radio-theme($theme);

    mat-radio-button {
        .mdc-radio__background {
            &::before {
                top: -25% !important;
                left: -25% !important;
            }
        }

        .mdc-radio__outer-circle {
            border-width: 1px;
        }
    }
}
