@use '@angular/material' as mat;

@mixin ui-navbar-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .ui-navbar__nav-entry-container {
        .ui-navbar {
            background-color: transparent;

            &__nav-entry-link {
                --mdc-text-button-label-text-color: #{mat.get-color-from-palette(
                        $foreground,
                        text,
                        0.7
                    )};

                &--active {
                    background: transparent;
                    --mdc-text-button-label-text-color: #{mat.get-color-from-palette(
                            $foreground,
                            text
                        )};
                }

                &.mat-button[disabled] {
                    --mdc-text-button-disabled-label-text-color: #{mat.get-color-from-palette(
                            $foreground,
                            text,
                            0.15
                        )};
                }
            }
        }
    }
}
