@use '@angular/material' as mat;
@use '../../styles/variables' as vars;

@mixin ui-tabs-theme($theme) {
    @include mat.tabs-theme($theme);

    .mat-mdc-tab {
        transition: color vars.$transition-fade-duration-fast;
        --mat-tab-header-label-text-letter-spacing: 0.6px;

        &.mdc-tab--active {
            opacity: 1;
        }
    }
}
