@use '@angular/material' as mat;

@mixin ui-navbar-theme($theme, $fade-duration: 0.2s) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    $theme-colors: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    );

    .ui-navbar__nav-entry-container {
        .ui-navbar {
            background-color: mat.get-color-from-palette($background, app-bar);

            &__nav-entry-link {
                background: transparent;
                --mdc-text-button-label-text-color: #{mat.get-color-from-palette(
                        $foreground,
                        text,
                        0.3
                    )};

                transition: background $fade-duration, color $fade-duration;

                &--active {
                    background: mat.get-color-from-palette(
                        $background,
                        status-bar,
                        0.3
                    );
                    --mdc-text-button-label-text-color: #{mat.get-color-from-palette(
                            $foreground,
                            text
                        )};
                }

                &.mat-mdc-button[disabled] {
                    --mdc-text-button-disabled-label-text-color: #{mat.get-color-from-palette(
                            $foreground,
                            text,
                            0.15
                        )};
                }
            }

            @each $name, $color in $theme-colors {
                // Set the active color of the entries
                &.mat-#{$name} {
                    .ui-navbar {
                        &__nav-entry-link {
                            &--active {
                                color: mat.get-color-from-palette($color);
                            }
                        }
                    }
                }
            }
        }
    }
}
