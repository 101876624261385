@use '@angular/material' as mat;

@mixin ui-slide-toggle-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $theme-colors: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    );

    $slide-toggle-height: 18px;
    $slide-toggle-thumb-height: 14px;

    .mat-mdc-slide-toggle {
        .mdc-switch__track {
            --mdc-switch-track-height: #{$slide-toggle-height};
            --mdc-switch-unselected-track-color: transparent;
            --mdc-switch-selected-hover-track-color: transparent;
            --mdc-switch-track-shape: #{$slide-toggle-height * 0.5};
            --mat-switch-track-outline-width: 0;

            border: 1px solid mat.get-color-from-palette($foreground, base) !important;
        }

        .mdc-switch__handle {
            --mdc-switch-handle-width: #{$slide-toggle-thumb-height};
            --mdc-switch-handle-height: #{$slide-toggle-thumb-height};
            --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 0 0
                2px;
            --mat-switch-selected-with-icon-handle-horizontal-margin: 0 0 0 2px;
            --mat-switch-unselected-pressed-handle-horizontal-margin: 0 0 0 2px;
            --mat-switch-selected-pressed-handle-horizontal-margin: 0 0 0 2px;
            --mat-switch-unselected-handle-size: #{$slide-toggle-thumb-height};
            --mat-switch-selected-handle-size: #{$slide-toggle-thumb-height};
            --mat-switch-pressed-handle-size: #{$slide-toggle-thumb-height};
            --mat-switch-with-icon-handle-size: #{$slide-toggle-thumb-height};

            top: 2px;
            background: #a8a8a8;
            box-shadow: none;
            transform: none;
        }

        .mdc-switch__icons {
            display: none;
        }

        .mdc-switch__shadow {
            display: none;
        }

        &.mat-mdc-slide-toggle-checked {
            @if map-get($theme, is-dark) {
                .mdc-switch__track {
                    --mdc-switch-selected-track-color: white;
                    --mdc-switch-selected-hover-track-color: white;
                }

                .mdc-switch__handle {
                    --mdc-switch-handle-surface-color: black;
                    --mdc-switch-selected-handle-color: black;
                    --mdc-switch-selected-hover-handle-color: black;
                    --mdc-switch-selected-focus-handle-color: black;
                    --mdc-switch-selected-pressed-handle-color: black;
                }
            } @else {
                .mdc-switch__track {
                    --mdc-switch-selected-track-color: black;
                    --mdc-switch-selected-hover-track-color: black;
                }

                .mdc-switch__handle {
                    --mdc-switch-handle-surface-color: white;
                    --mdc-switch-selected-handle-color: white;
                    --mdc-switch-selected-hover-handle-color: white;
                    --mdc-switch-selected-focus-handle-color: white;
                    --mdc-switch-selected-pressed-handle-color: white;
                }
            }

            .mdc-switch__handle {
                transform: translate3d(2px, 0, 0) !important;
            }
        }

        .mdc-switch {
            margin-right: 10px;
        }

        .mdc-form-field--align-end {
            .mdc-switch {
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }
}
