@use '@angular/material' as mat;

@use '../../styles/variables';

@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/pagination/pagination.scss';

@mixin ui-swiper-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    :root {
        --swiper-theme-color: #{mat.get-color-from-palette($foreground, base)};
    }

    .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
        transition: all variables.$transition-fade-duration-fast;
        margin: 0 !important;
        background: transparent;
        opacity: 0.2;

        &-active {
            opacity: 1;
        }

        &:before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            margin: 5px;
            background: #{mat.get-color-from-palette($foreground, base)};
            border-radius: 50%;
        }
    }
}
