@use 'ui-buttons.overrides';
@use '../breakpoints';

@mixin ui-notification-theme-overrides($theme) {
    $button-height: 45px;

    .notification {
        &__message {
            line-height: 1.5;

            &--has-close {
                max-width: 100% !important;
            }
        }

        &__row-2 {
            @media #{breakpoints.$handset-portrait}, #{breakpoints.$tablet-portrait} {
                .notification__action {
                    width: 100%;
                }
            }
        }

        &__action-close {
            margin: -8px -8px -8px 0 !important;
        }
    }

    .mat-mdc-snack-bar-container {
        &.mat-mdc-snack-bar-center {
            max-width: 50vw;
        }

        @include ui-buttons.ui-button-invert($theme);

        button.mdc-button.mat-mdc-button-base {
            line-height: $button-height;
            &.mdc-button--outlined {
                line-height: $button-height - 4px;
            }
        }
    }
}
