@use '@angular/material' as mat;

@mixin ui-slider-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $theme-colors: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    );

    $slider-height: 14px;

    .mat-slider {
        &.mat-slider-horizontal {
            .mat-slider-track-wrapper,
            .mat-slider-track-background,
            .mat-slider-track-fill,
            .mat-slider-ticks-container,
            .mat-slider-ticks {
                height: $slider-height;
            }

            .mat-slider-track-wrapper {
                border-radius: $slider-height * 0.5;
                width: calc(100% + #{$slider-height});
                margin-left: -1 * ($slider-height * 0.5);
                // required for mobile-safari to correctly clip overflow
                transform: translate3d(0, 0, 0);
            }

            .mat-slider-wrapper {
                height: $slider-height;
                top: 17px;
            }
        }

        &.mat-slider-min-value:not(.mat-slider-thumb-label-showing) {
            .mat-slider-thumb {
                background-color: mat.get-color-from-palette($foreground, base);
            }
        }

        &.mat-primary,
        &.mat-accent,
        &.mat-warn {
            .mat-slider-thumb {
                background-color: mat.get-color-from-palette($foreground, base);
            }
        }

        .mat-slider-track-background {
            background-color: mat.get-color-from-palette(
                $foreground,
                base,
                0.1
            );
        }

        &:hover,
        &.cdk-focused {
            .mat-slider-track-background {
                background-color: mat.get-color-from-palette(
                    $foreground,
                    base,
                    0.15
                );
            }
        }

        @each $name, $color in $theme-colors {
            &.mat-#{$name} {
                .mat-slider-track-fill {
                    background-color: mat.get-color-from-palette($color);
                }
            }
        }
    }
}
