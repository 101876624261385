@use '@angular/material' as mat;
@use 'libs/aml/ui-components-theme/src/styles/material-theme';
@use 'libs/aml/ui-components-theme/src/styles/mhp-theme';
@use 'libs/aml/ui-components-theme/src/styles/component-overrides/ui-components.overrides'
    as componentOverrides;
@use 'libs/aml/ui-components-theme/src/styles/variables' as vars;
@use 'libs/aml/ui-components-theme/src/styles/breakpoints';
@use 'libs/aml/ui-components-theme/src/styles/mixins/background';
@use 'styles/shared/forms';
@use 'styles/shared/legal';

/* You can add global styles to this file, and also import other style files */
@import 'normalize.css/normalize';
// we need to declare the fonts as css file here as scss-loader does not resolve paths correctly
@import '../../../../libs/aml/ui-components-theme/src/styles/fonts.css';

html {
    width: 100%;

    /* 100% height should be fine for most user-agents, but on Android, we could let the user scroll away the nav-bar to have more space available for the configurator
     * This would require us to set the html-height to 100vh. BUT, this breaks iOS which adds the additional height to the viewport
     * but does not allow the user to "scroll-away" the navbar
     *
     * See https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
     * See https://developers.google.com/web/updates/2016/12/url-bar-resizing
     */
    @supports (-webkit-touch-callout: none) {
        /* CSS for iOS devices */
        height: 100%;
        top: 0;
        left: 0;
    }

    @supports not (-webkit-touch-callout: none) {
        /* CSS for other than iOS devices */
        height: 100vh;

        /**
         * For tablet and web resolutions, always use 100% height to have all relevant content visible
         */
        @media #{breakpoints.$tablet}, #{breakpoints.$web} {
            height: 100%;
        }
    }

    background-color: #2b2d2f;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: vars.$font-primary;
    @include background.gradient-background();
}

h1,
h2,
h3 {
    font-family: vars.$font-secondary;
}

.grecaptcha-badge {
    visibility: hidden;
}

// Theme Init
/*
 * Disable duplication warnings for now as we need to re-work how component-styles are provided.
 * We would need to separate colors and typography in the theme-definitions and in places where color-adjustments
 * are required (e.g. inversion of colors in ui-mat-dialog component) do only include the according color-parts of
 * the component themes.
 * https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
 */
mat.$theme-ignore-duplication-warnings: true;

@include mhp-theme.mhp-theme();
@include componentOverrides.ui-components-theme-overrides(
    material-theme.$theme
);
