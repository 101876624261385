@use '@angular/material' as mat;

@mixin ui-grid-list-item-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, primary);

    .ui-grid-list-item {
        &__image {
            background-color: mat.get-color-from-palette(
                $background,
                card,
                0.2
            );
        }

        &__icon {
            color: mat.get-color-from-palette($primary, default);
        }

        &--has-subtitle {
            .ui-grid-list-item__subtitle {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
