/**
* ==============================
* AML Fonts
* ==============================
*/

/**
* ===============
* AML Flare Font
* ===============
*/

@font-face {
    font-family: AstonMartinFlare;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/AstonMartinFlare/woff2/AstonMartinFlare_W_Rg.woff2')
            format('woff2'),
        url('../assets/fonts/AstonMartinFlare/woff/AstonMartinFlare_W_Rg.woff')
            format('woff');
}
@font-face {
    font-family: AstonMartinFlare;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/AstonMartinFlare/woff2/AstonMartinFlare_W_Bd.woff2')
            format('woff2'),
        url('../assets/fonts/AstonMartinFlare/woff/AstonMartinFlare_W_Bd.woff')
            format('woff');
}

/**
* ===============
* AML Sans Font
* ===============
*/

@font-face {
    font-family: AstonMartinSans;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/AstonMartinSans/woff2/AstonMartinSans_W_Rg.woff2')
            format('woff2'),
        url('../assets/fonts/AstonMartinSans/woff/AstonMartinSans_W_Rg.woff')
            format('woff');
}

@font-face {
    font-family: AstonMartinSans;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/AstonMartinSans/woff2/AstonMartinSans_W_Lt.woff2')
            format('woff2'),
        url('../assets/fonts/AstonMartinSans/woff/AstonMartinSans_W_Lt.woff')
            format('woff');
}
