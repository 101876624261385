@use '@angular/material' as mat;
@use '../../styles/variables';

@mixin ui-toast-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $is-dark-theme: map-get($theme, is-dark);

    $theme-colors: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    );

    mhp-ui-toast {
        transition: box-shadow variables.$transition-fade-duration-fast;
        @include mat.private-theme-elevation(2, $theme);

        &:hover {
            @include mat.private-theme-elevation(4, $theme);
        }

        .ui-toast {
            color: if($is-dark-theme, rgba(black, 0.87), rgba(white, 0.7));
            background: if(
                $is-dark-theme,
                map-get(mat.$gray-palette, 50),
                #323232
            );
        }

        @each $name, $color in $theme-colors {
            .ui-toast {
                &.mat-#{$name} {
                    background-color: mat.get-color-from-palette($color);
                    color: mat.get-color-from-palette($color, default-contrast);
                }
            }
        }
    }
}
