@use '@angular/material' as mat;

@mixin ui-progress-spinner-theme($theme) {
    @include mat.progress-spinner-theme($theme);

    $foreground: map-get($theme, foreground);

    .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: #{mat.get-color-from-palette(
                $foreground,
                base
            )};
    }
}
