@mixin ui-radio-theme-overrides($theme) {
    $radio-dimension: 28px;

    mat-radio-button {
        &.mat-mdc-radio-button {
            .mdc-radio {
                padding-top: 5px;
            }

            .mdc-radio__background {
                width: $radio-dimension;
                height: $radio-dimension;

                .mdc-radio__outer-circle {
                    border-width: 1px;
                    border-color: #fff;
                    width: $radio-dimension;
                    height: $radio-dimension;
                }

                .mdc-radio__inner-circle {
                    background-color: #fff;
                    width: $radio-dimension;
                    height: $radio-dimension;
                    transform: scale(0);
                }
            }

            &.mat-mdc-radio-checked {
                .mdc-radio__background {
                    .mdc-radio__inner-circle {
                        transform: scale(0.7);
                    }
                }
            }

            .mdc-form-field {
                .mdc-label {
                    padding-left: 12px;
                }
            }
        }
    }

    /*
    mat-radio-button {
        &.mat-mdc-radio-button {
            .mdc-radio__background {
                width: $radio-dimension;
                height: $radio-dimension;

                .mdc-radio__outer-circle {
                    border-width: 1px;
                    border-color: #fff;
                    width: $radio-dimension;
                    height: $radio-dimension;
                }

                .mdc-radio__inner-circle {
                    background-color: #fff;
                    width: $radio-dimension;
                    height: $radio-dimension;
                    transform: scale(0);
                }
            }

            &.mat-mdc-radio-checked {
                .mdc-radio__background {
                    .mdc-radio__inner-circle {
                        transform: scale(0.7);
                    }
                }
            }

            .mdc-form-field {
                .mdc-label {
                    padding-left: 12px;
                }
            }
        }
    }*/
}
