@use '@angular/material' as mat;
@use 'libs/aml/ui-components-theme/src/styles/material-theme';
@use 'libs/aml/ui-components-theme/src/styles/variables';
@use 'libs/aml/ui-components-theme/src/styles/breakpoints';
@use 'libs/aml/ui-components-theme/src/styles/mixins/background';

.aml-form {
    &__form-block {
        margin-bottom: variables.$spacing-xl;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media #{breakpoints.$bp-gt-sm} {
            margin-bottom: variables.$spacing-xxl;
        }
    }

    &__field-group {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &__form-field {
        &--aside {
            @media #{breakpoints.$bp-gt-sm} {
                width: calc(50% - #{variables.$spacing-s * 0.5}) !important;
            }
        }

        &--lastname {
            .aml-form__form--ja & {
                order: -1;
            }
        }
    }

    &__actions {
        @media #{breakpoints.$bp-lt-md} {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 100;

            $background: map-get(material-theme.$theme, background);
            @include background.blurry-background(
                rgba(mat.get-color-from-palette($background, background), 0.6),
                rgba(#23262b, 0.9)
            );

            > button {
                width: 100%;
                margin-left: 0;
            }
        }

        @media #{breakpoints.$bp-gt-sm} {
            position: static;
            @include background.clear-blurry-background();

            button {
                margin-top: variables.$spacing-m;
                width: auto;

                & + button {
                    margin-top: 0;
                }
            }
        }
    }
}
