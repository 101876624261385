/*
 * This file is meant as a base for providing defaults to the ui-components.
 */

$border-radius: 3px;

// -----------------------------------------------------------------------------
// SPACING
// -----------------------------------------------------------------------------

$spacing-xxs: 4px !default;
$spacing-xs: 8px !default;
$spacing-s: 12px !default;
$spacing-m: 16px !default;
$spacing-l: 20px !default;
$spacing-xl: 24px !default;
$spacing-xxl: 40px !default;
$spacing-xxxl: 48px !default;

// -----------------------------------------------------------------------------
// TYPOGHRAPHY
// -----------------------------------------------------------------------------

$font-size-xxs: 7px !default;
$font-size-xs: 8px !default;
$font-size-s: 10px !default;
$font-size-m: 12px !default;
$font-size-l: 13px !default;
$font-size-xl: 14px !default;

$font-size-title-m: 13px !default;
$font-size-title-l: 21px !default;
$font-size-title-xl: 26px !default;
$font-size-title-xxl: 32px !default;

$line-height-s: 1.2 !default;
$line-height-m: 1.4 !default;
$line-height-l: 1.8 !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

// -----------------------------------------------------------------------------
// TRANSITIONS
// -----------------------------------------------------------------------------

$transition-fade-duration-fast: 0.2s !default;

// -----------------------------------------------------------------------------
// ICON SIZING
// -----------------------------------------------------------------------------
$icon-size: (
    xxsmall: 8px,
    xsmall: 12px,
    small: 16px,
    medium: 20px,
    large: 26px,
    xlarge: 32px
) !default;

// -----------------------------------------------------------------------------
// DIALOGS
// -----------------------------------------------------------------------------
$dialog-spacing-small: 20px !default;
$dialog-spacing: 40px !default;
