$mhp-checkbox-dimension: 20px;

@mixin ui-checkbox-theme-overrides($theme) {
    $checkmark-width: 80%;

    mat-checkbox {
        .mdc-checkbox__background {
            width: $mhp-checkbox-dimension;
            height: $mhp-checkbox-dimension;
        }

        //resizing ripple does not properly work for mat-checkbox as animated ripple is set to a fixed-size 20px radius.
        //See https://github.com/angular/components/blob/master/src/material/checkbox/checkbox.html#L21
        //.mat-checkbox .mat-checkbox-ripple {
        //    $ripple-dimension: $checkbox-dimension + 30px;
        //    width: $ripple-dimension;
        //    height: $ripple-dimension;
        //    left: calc(50% - #{($ripple-dimension) / 2});
        //    top: calc(50% - #{($ripple-dimension) / 2});
        //}

        .mdc-checkbox__checkmark {
            width: $checkmark-width;
            top: (100% - $checkmark-width) * 0.5;
            right: (100% - $checkmark-width) * 0.5;
            bottom: (100% - $checkmark-width) * 0.5;
            left: (100% - $checkmark-width) * 0.5;
        }
    }
}
