@use '@angular/material' as mat;
@use 'variables';

$mat-typography: mat.define-typography-config(
    $font-family: variables.$font-primary,
    $subtitle-2:
        mat.define-typography-level(
            variables.$font-size-l,
            variables.$line-height-m,
            variables.$font-weight-medium,
            variables.$font-secondary
        ),
    $subtitle-1:
        mat.define-typography-level(
            variables.$font-size-l,
            variables.$line-height-m,
            variables.$font-weight-medium,
            variables.$font-secondary
        ),
    $body-2:
        mat.define-typography-level(
            variables.$font-size-l,
            variables.$line-height-l,
            variables.$font-weight-medium
        ),
    $body-1:
        mat.define-typography-level(
            variables.$font-size-l,
            variables.$line-height-m,
            variables.$font-weight-normal
        ),
    $caption:
        mat.define-typography-level(
            variables.$font-size-s,
            variables.$line-height-l,
            variables.$font-weight-normal
        ),
    $button:
        mat.define-typography-level(
            variables.$font-size-l,
            variables.$font-size-m,
            variables.$font-weight-normal,
            variables.$font-secondary
        )
) !default;

/**
 * See http://mcg.mbitson.com for generating material-palettes with proper scss output.
 */
$mat-primary: (
    50: #f8f8f8,
    100: #ededed,
    200: #e1e1e1,
    300: #d4d4d4,
    400: #cbcbcb,
    500: #c2c2c2,
    600: #bcbcbc,
    700: #b4b4b4,
    800: #acacac,
    900: #9f9f9f,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffe0e0,
    A700: #ffc7c7,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
) !default;

$mat-primary-for-light: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #262626,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #a6a6a6,
    A200: #8c8c8c,
    A400: #737373,
    A700: #666666,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff
    )
) !default;

$mat-accent: (
    50: #f4efeb,
    100: #e4d7cc,
    200: #d2bdab,
    300: #c0a389,
    400: #b38f6f,
    500: #a57b56,
    600: #9d734f,
    700: #936845,
    800: #8a5e3c,
    900: #794b2b,
    A100: #ffd8bf,
    A200: #ffb98c,
    A400: #ff9a59,
    A700: #ff8a40,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
) !default;

$mat-warn: (
    50: #fff2e0,
    100: #ffdfb3,
    200: #ffc980,
    300: #ffb34d,
    400: #ffa326,
    500: #ff9300,
    600: #ff8b00,
    700: #ff8000,
    800: #ff7600,
    900: #ff6400,
    A100: #ffffff,
    A200: #fff6f2,
    A400: #ffd4bf,
    A700: #ffc3a6,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
) !default;

$theme-primary: mat.define-palette($mat-primary, $default: A100) !default;
$theme-primary-for-light: mat.define-palette($mat-primary-for-light) !default;
$theme-accent: mat.define-palette($mat-accent) !default;
$theme-warn: mat.define-palette($mat-warn) !default;

$darkTheme: mat.define-dark-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn
        ),
        typography: $mat-typography,
        density: 0
    )
) !default;
$lightTheme: mat.define-light-theme(
    (
        color: (
            primary: $theme-primary-for-light,
            accent: $theme-accent,
            warn: $theme-warn
        ),
        typography: $mat-typography,
        density: 0
    )
) !default;

$theme: $darkTheme !default;
