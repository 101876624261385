@use 'sass:math';
@use '@angular/material' as mat;
@use '../variables';

@mixin ui-select-theme-overrides($theme) {
    $foreground: map-get($theme, foreground);

    .mat-mdc-select-panel {
        border-radius: 2px;

        .mat-mdc-option {
            .mat-icon {
                margin-right: 0;
            }
        }
    }

    $arrow-dimension: 20px;

    .mat-mdc-select {
        .mat-mdc-select-arrow {
            --mat-select-enabled-arrow-color: #{mat.get-color-from-palette($foreground, base)};

            border: 0;
            width: $arrow-dimension;
            height: $arrow-dimension;
            display: block;
            position: relative;
            margin: 0 0 0 variables.$spacing-m;

            > svg {
                display: none;
            }

            &:before,
            &:after {
                content: '';
                top: math.div($arrow-dimension, 20) * 8;
                position: absolute;
                width: math.div($arrow-dimension, 20) * 12.5;
                height: math.div($arrow-dimension, 20) * 2.5;
                background-color: currentColor;
                display: block;
                border-radius: 1.2px;
            }

            &:before {
                left: 0;
                transform: rotate(45deg);
            }

            &:after {
                right: 0;
                transform: rotate(-45deg);
            }
        }
    }
}
