@use '@angular/material' as mat;

@use 'ui-buttons.overrides' as ui-buttons-overrides;
@use 'libs/ui-components/src/styles/variables' as componentVariables;
@use '../variables';
@use '../material-theme';
@use '../component-overrides/ui-select.overrides';
@use '../component-overrides/ui-checkbox.overrides';
@use '../component-overrides/ui-slide-toggle.overrides';
@use '../ui-components-theme';
@use '../mixins/background';
@use '../breakpoints';

@mixin ui-mat-dialog-theme-overrides(
    $theme,
    $light-theme: material-theme.$lightTheme,
    $dark-theme: material-theme.$darkTheme
) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .ui-mat-dialog {
        &__header-title {
            font-family: variables.$font-secondary;
            text-transform: none;
        }

        &__header-subtitle {
            text-transform: none;
        }
    }

    .mhp-ui-modal-panel {
        .mat-mdc-dialog-container {
            .mdc-dialog__surface {
                background-color: transparent;
            }
        }

        &.mhp-ui-modal-panel--fullscreen {
            .mat-dialog-container {
                background: transparent;
                padding: 0;
            }
        }

        &.mhp-ui-modal-panel--no-padding {
            .mat-dialog-container {
                padding: 0;
            }
        }

        &.mhp-ui-modal-panel--no-overflow {
            .mat-dialog-container {
                overflow: hidden;
                height: auto;
            }
        }

        &.mhp-ui-modal-panel--stretch-buttons {
            .ui-mat-dialog {
                &__actions {
                    button {
                        max-width: none !important;
                    }
                }
            }
        }

        &:not(.mhp-ui-modal-panel--fullscreen) {
            max-width: calc(100% - #{2 * variables.$spacing-m});

            @media #{breakpoints.$bp-gt-sm} {
                max-width: 65vw;
            }

            // FIXME: Include only color-theme-part instead of whole theme
            .ui-mat-dialog {
                @if map-get($theme, is-dark) {
                    @include mat.core-theme($light-theme);
                    // include only what's needed
                    @include ui-components-theme.ui-select-theme($light-theme);
                    @include ui-components-theme.ui-form-field-theme(
                        $light-theme
                    );
                    @include ui-components-theme.ui-checkbox-theme(
                        $light-theme
                    );
                    @include ui-components-theme.ui-input-theme($light-theme);
                    @include mat.slide-toggle-theme($light-theme);
                    @include ui-components-theme.ui-progress-spinner-theme(
                        $light-theme
                    );
                    @include ui-select.ui-select-theme-overrides($light-theme);
                    @include ui-checkbox.ui-checkbox-theme-overrides(
                        $light-theme
                    );
                    @include ui-slide-toggle.ui-slide-toggle-theme-overrides(
                        $light-theme
                    );
                    @include mat.button-theme($light-theme);
                    @include ui-buttons-overrides.ui-buttons-theme-overrides(
                        $light-theme
                    );
                } @else {
                    @include mat.core-theme($dark-theme);
                    // include only what's needed
                    @include ui-components-theme.ui-select-theme($dark-theme);
                    @include ui-components-theme.ui-form-field-theme(
                        $dark-theme
                    );
                    @include ui-components-theme.ui-checkbox-theme($dark-theme);
                    @include ui-components-theme.ui-input-theme($dark-theme);
                    @include mat.slide-toggle-theme($dark-theme);
                    @include ui-components-theme.ui-progress-spinner-theme(
                        $dark-theme
                    );
                    @include ui-select.ui-select-theme-overrides($dark-theme);
                    @include ui-checkbox.ui-checkbox-theme-overrides(
                        $dark-theme
                    );
                    @include ui-slide-toggle.ui-slide-toggle-theme-overrides(
                        $dark-theme
                    );
                    @include mat.button-theme($dark-theme);
                    @include ui-buttons-overrides.ui-buttons-theme-overrides(
                        $dark-theme
                    );
                }

                background-color: mat.get-color-from-palette($foreground, base);
                color: mat.get-color-from-palette($background, status-bar);
                --mdc-dialog-supporting-text-color: #{mat.get-color-from-palette(
                        $background,
                        status-bar
                    )};

                &__header {
                    padding: componentVariables.$dialog-spacing-small
                        componentVariables.$dialog-spacing-small
                        variables.$spacing-m;

                    &-title {
                        font-size: variables.$font-size-title-xl;
                    }

                    @media #{breakpoints.$bp-gt-xs} {
                        padding: variables.$dialog-spacing
                            variables.$dialog-spacing variables.$spacing-m;

                        &-title {
                            font-size: variables.$font-size-title-xxl;
                        }
                    }

                    &:empty {
                        padding-bottom: 0;
                    }
                }

                &__content {
                    padding: 0 componentVariables.$dialog-spacing-small;

                    &--no-actions {
                        padding-bottom: componentVariables.$dialog-spacing-small;
                    }

                    p {
                        font-size: variables.$font-size-l;
                        line-height: variables.$line-height-m;
                    }

                    @media #{breakpoints.$bp-gt-xs} {
                        padding: 0 variables.$dialog-spacing;

                        &--no-actions {
                            padding-bottom: variables.$dialog-spacing;
                        }

                        p {
                            line-height: variables.$line-height-xl;
                        }
                    }
                }

                &__actions {
                    height: auto;
                    padding: variables.$spacing-m
                        componentVariables.$dialog-spacing-small
                        componentVariables.$dialog-spacing-small;
                    background-color: transparent;

                    @media #{breakpoints.$bp-lt-sm} {
                        align-items: stretch;
                        flex-direction: column-reverse;
                        gap: variables.$spacing-m;
                    }

                    @media #{breakpoints.$bp-gt-xs} {
                        padding: variables.$spacing-m variables.$dialog-spacing
                            variables.$dialog-spacing;
                    }

                    @include ui-buttons-overrides.ui-button-invert($theme);

                    &-left,
                    &-right {
                        flex-basis: 0;
                    }

                    &-left {
                        mhp-ui-mat-dialog-actions-left {
                            flex: 1;
                            display: flex;
                            justify-content: flex-start;
                            margin-right: variables.$spacing-xxs;

                            > * {
                                flex: 1;
                            }

                            button {
                                max-width: 300px;
                            }

                            @media #{breakpoints.$bp-lt-sm} {
                                margin-right: 0;
                                button {
                                    max-width: none;
                                }
                            }
                        }
                    }

                    &-right {
                        mhp-ui-mat-dialog-actions-right {
                            flex: 1;
                            display: flex;
                            justify-content: flex-end;
                            margin-left: variables.$spacing-xxs;

                            > * {
                                flex: 1;
                            }

                            button {
                                max-width: 300px;
                            }

                            @media #{breakpoints.$bp-lt-sm} {
                                margin-left: 0;
                                button {
                                    max-width: none;
                                }
                            }
                        }
                    }

                    &--left-empty {
                        mhp-ui-mat-dialog-actions-right {
                            margin-left: 0;
                        }
                    }

                    &--right-empty {
                        mhp-ui-mat-dialog-actions-left {
                            margin-right: 0;
                        }
                    }

                    .mat-button {
                        &:hover {
                            span {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .mhp-ui-modal-backdrop {
        &.mhp-ui-modal-backdrop--fullscreen,
        &.mhp-ui-modal-backdrop--blurry {
            @include background.blurry-background(
                rgba(#000, 0.44),
                rgba(#000, 0.95)
            );
        }
    }

    mat-mdc-dialog-container {
        &.mat-mdc-dialog-container {
            border-radius: 0;
        }
    }
}
