$border-radius: 3px;

// -----------------------------------------------------------------------------
// SPACING
// -----------------------------------------------------------------------------

$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-s: 15px;
$spacing-m: 20px;
$spacing-l: 25px;
$spacing-xl: 30px;
$spacing-xxl: 40px;
$spacing-xxxl: 60px;

// -----------------------------------------------------------------------------
// TYPOGHRAPHY
// -----------------------------------------------------------------------------

$font-primary: AstonMartinSans, sans-serif;
$font-secondary: AstonMartinFlare, sans-serif;
// fallback-languages for non-western languages
$font-primary-asian: Arial, sans-serif;
$font-secondary-asian: Arial, sans-serif;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 13px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 18px;

$font-size-title-m: 20px;
$font-size-title-l: 22px;
$font-size-title-xl: 26px;
$font-size-title-xxl: 32px;

$line-height-s: 1.2;
$line-height-m: 1.5;
$line-height-l: 1.8;
$line-height-xl: 2;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// -----------------------------------------------------------------------------
// TRANSITIONS
// -----------------------------------------------------------------------------

$transition-fade-duration-fast: 0.2s;

// -----------------------------------------------------------------------------
// ICON SIZING
// -----------------------------------------------------------------------------
$icon-size: (
    xxsmall: 8px,
    xsmall: 12px,
    small: 16px,
    medium: 20px,
    large: 26px,
    xlarge: 32px
);

// -----------------------------------------------------------------------------
// DIALOGS
// -----------------------------------------------------------------------------
$dialog-spacing: 40px;

// -----------------------------------------------------------------------------
// COLORS
// -----------------------------------------------------------------------------

$gray-selected-color: #292b2d;
$aml-green: #00665e;
