@use '@angular/material' as mat;
@use '../../styles/mixins/ui-helpers';

@mixin ui-sticky-header-list-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .sticky-header-list {
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);

        &__group-header {
            background: mat.get-color-from-palette($background, card);
            @include ui-helpers.divider-list(
                false,
                mat.get-color-from-palette($foreground, divider)
            );
        }
    }
}
